<template>
  <div>
    <m-bread-crumb/>

<section class="blog spad">
        <div class="container">
            <div class="row">
                
                <div class="col-lg-9">
                    <div class="row">
                    <h5>Conheça as soluções de crédito que o AC Microbanco lhe oferece para realizar os seus projectos.
                    Saiba mais e descubra a melhor opção para si.
                    </h5>
                    <br>
                    </div>
                </div>
                
                <div class="col-lg-9">
                    <div class="row">
                        
                        
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="blog__item">
                                
                            
                                
                                <div class="box21">
                                <img src="img/blog/blog-10.jpg" alt="">
                                    <div class="box-content">
                                        <h4 class="title" style="color:white;">Crédito ao Consumo</h4>
                                        
                                        <div class="card card-body">
                                        <p style="text-align: justify"><i class="fa fa-chevron-circle-right" style="color:red;"></i>
                                        <router-link :to="{name: 'CreditoPessoalRapido'}" onMouseOver="this.style.color='red'"
        onMouseOut="this.style.color='blue'">Crédito Pessoal Rapido</router-link><br>
		                              <i class="fa fa-chevron-circle-right" style="color:red;"></i> 
                                  <router-link :to="{name: 'CreditoAutomovel'}" onMouseOver="this.style.color='red'"
        onMouseOut="this.style.color='blue'">Crédito Automovel</router-link><br>
                                          
		                              <i class="fa fa-chevron-circle-right" style="color:red;"></i> 
                                        <router-link :to="{name: 'CreditoLarRecheio'}" onMouseOver="this.style.color='red'"
        onMouseOut="this.style.color='blue'">Crédito Lar Recheio</router-link><br>
                                        
                                    </p>
                                      </div>
                                        <a class="read-more" href="particulares.html#consumo">Saber mais</a>
                                    </div>
                                </div>
                                
                            
                        </div>
                            </div>
                        
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="blog__item">
                               
                            <div class="box21">
                                <img src="img/blog/blog-11.jpg" alt="">
                                    <div class="box-content">
                                        <h4 class="title" style="color:white;">Crédito à Habitação</h4>
                                        
                                    
                                      <div class="card card-body">
                                        <p style="text-align: justify"><i class="fa fa-chevron-circle-right" style="color:red;"></i>
                                        <router-link :to="{name: 'CreditoHabitacao'}" onMouseOver="this.style.color='red'"
        onMouseOut="this.style.color='blue'">Crédito Habitação</router-link><br>
           
		                              <i class="fa fa-chevron-circle-right" style="color:red;"></i> 
                                        <router-link :to="{name: 'CreditoConstrucao'}" onMouseOver="this.style.color='red'"
        onMouseOut="this.style.color='blue'">Crédito Construção</router-link><br>
                                        
                                    </p>
                                      </div>
                                   
                                        <a class="read-more" href="particulares.html#habitacao">Saber mais</a>
                                    </div>
                            </div>
                                
                                
                            
                            </div>
                                
                                
                        </div>
                       
                    </div>
                   
                </div>
                <div class="col-lg-3 col-md-6 col-sm-9">
                    <div class="blog__sidebar">
                        <form action="#" class="blog__sidebar__search">
                            <input type="text" placeholder="Procurar...">
                            <button type="submit"><i class="fa fa-search"></i></button>
                        </form>
                        
                        <div class="blog__sidebar__categories">
                            <h4>Créditos</h4>
                            <ul>
                              <li><router-link :to="{name: 'Particulares'}">Particulares</router-link></li>
                              <li><router-link :to="{name: 'Empresas'}">Empresas</router-link></li> 
                              
                                
                            </ul>
                        </div>
                        

                    </div>
                </div>
            </div>
        </div>
        
    
        
</section>
    

<section class="about spad" id="consumo">
        <div class="container">
            <div class="row">
                
<div class="col-lg-6 col-md-6">
    <div class="contact__text">
            <div class="section-title">
                            <h3>Crédito Pessoal ao <b>Consumo</b></h3>
                            <h2 style="color: red">O crédito que realiza os seus sonhos.</h2>
                        <p style="text-align:justify">Tem um sonho por realizar, mas precisa de financiamento para o concretizar? Conheça o <b>Crédito ao Consumo</b> que o apoia quando precisa nas despesas que são importantes para si.
                        </p>
            </div>
    </div>
</div>
<div class="col-lg-6 col-md-6">
     <div class="car__details__pic">
          <div class="car__details__pic__large">
            <img class="car-big-img" src="img/cars/details/cd-10.png" alt="">
           </div>
                        
     </div>
</div>
                
<div id="accordion" class="col-lg-12">
  <div class="card">
    <div class="card-header" id="headingOne">
      <h5 class="mb-0">
        <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
          Finalidade
        </button>
      </h5>
    </div>

    <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
      <div class="card-body">
        <ul>
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>
            O Crédito ao Consumo destina-se ao financiamento de compras de diversos tipos.
        </ul>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header" id="headingTwo">
      <h5 class="mb-0" >
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"> 
          Público – Alvo
        </button>
              
      </h5>
    </div>
    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
      <div class="card-body">
        <ul>
           <i class="fa fa-chevron-circle-right" style="color:red;"></i>	
            Clientes particulares do sector público e privado.
        </ul>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header" id="headingThree">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
          Vantagens
        </button>
      </h5>
    </div>
    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
      <div class="card-body">
        <ul>
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>	Versatilidade - O Crédito ao Consumo é exactamente isso: um crédito que pode usar para qualquer necessidade, seja ela qual for; <br />
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>	Financiamento até 100% do bem ou serviço a adquirir.<br />
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>
            Negociação do montante e prazo adequados a necessidade do cliente; <br />
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>
            Prestações ajustadas ao rendimento líquido do cliente <br />
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>
            Prestações mensais, iguais e consecutivas, de capital e juros <br />
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>
            Taxa de juro fixa<br />
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>
            O valor máximo é conforme a aprovação; <br />

                
        </ul>
      </div>
    </div>
  </div>
<div class="card">
    <div class="card-header" id="headingFour">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
          Despesas e comissões
        </button>
      </h5>
    </div>
    <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
      <div class="card-body">
        <ul>
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>	
            As despesas e comissões decorrentes do processo de financiamento serão debitadas automaticamente na conta do mutuário, de acordo com o plano financeiro acordado.<br />

            Em caso de liquidação antecipada, total ou parcial, do capital em dívida fica sujeito a penalização.        
        </ul>
      </div>
    </div>
  </div>
<div class="card">
    <div class="card-header" id="headingFive">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
          Características
        </button>
      </h5>
    </div>
    <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion">
         
        <div class="card card-body">
                <ul>
                    <p><i class="fa fa-chevron-circle-right" style="color:red;"></i>Idade do Cliente: 18 a 65 anos;<br>
		                              <i class="fa fa-chevron-circle-right" style="color:red;"></i> Volume: Conforme a aprovação;<br>
		                              <i class="fa fa-chevron-circle-right" style="color:red;"></i> Prazo: Minimo de 1 e máximo de 12 meses;<br>
                                    <i class="fa fa-chevron-circle-right" style="color:red;"></i> Taxa: Taxa de juro mensal fixa;<br/>
                                    <i class="fa fa-chevron-circle-right" style="color:red;"></i> Rendas: Fixas e ajustadas ao seu rendimento líquido 
                                       durante todo o período de vigência do contrato;<br>
		                           <i class="fa fa-chevron-circle-right" style="color:red;"></i> Garantias: * Livrança em branco (obrigatório) <br>
                                    <i class="fa fa-chevron-circle-right" style="color:red;"></i>Hipoteca de imóvel/Penhor DP<br>
		                           <i class="fa fa-chevron-circle-right" style="color:red;"></i>Possibilidade de liquidações antecipadas;<br/>
                                   <i class="fa fa-chevron-circle-right" style="color:red;"></i>Seguro de protecção ao Crédito que cobrira os seus 
                               encargos em caso de necessidades, protegendo-o
                            a si e aos seus.</p>
                </ul>
        </div>
     
        
    </div>
  </div>
<div class="card">
    <div class="card-header" id="headingSix">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
          Documentação
        </button>
      </h5>
    </div>
    <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordion">
         
        <div class="card card-body">
                <ul>
                    <p><i class="fa fa-chevron-circle-right" style="color:red;"></i>Idade do Cliente: 18 a 65 anos;<br>
		                              <i class="fa fa-chevron-circle-right" style="color:red;"></i> Formulário de solicitação de crédito devidamente preenchido;<br>
		                              <i class="fa fa-chevron-circle-right" style="color:red;"></i> Apresentação da cópia do documento de identificação;<br>
                                    <i class="fa fa-chevron-circle-right" style="color:red;"></i> Foto tipo passe;<br/>
                                    <i class="fa fa-chevron-circle-right" style="color:red;"></i> Cópia do NUIT;<br>
		                           <i class="fa fa-chevron-circle-right" style="color:red;"></i> Declaração do Bairro; <br>
                                    <i class="fa fa-chevron-circle-right" style="color:red;"></i>Declaração do Bairro;<br>
		                           <i class="fa fa-chevron-circle-right" style="color:red;"></i>Extracto bancário dos últimos 3 meses;<br/>
                                   </p>
                </ul>
        </div>    
    </div>
  </div>    
    
</div>
            </div>
        </div>
    
</section>
    
<section class="about spad" id="habitacao">
        <div class="container">
            <div class="row">
                
<div class="col-lg-6 col-md-6">
    <div class="contact__text">
            <div class="section-title">
                            <h3>Crédito Pessoal <b>à Habitação</b></h3>
                            <h2 style="color: red" >A solução que procura mora aqui.</h2>
                        <p style="text-align:justify">Modalidade de empréstimo de médio e longo prazo para fins de habitação, que pode obter junto do AC MicroBanco, em condições competitivas.
                        </p>
            </div>
    </div>
</div>
<div class="col-lg-6 col-md-6">
     <div class="car__details__pic">
          <div class="car__details__pic__large">
            <img class="car-big-img" src="img/cars/details/cd-12.jpg" alt="">
           </div>
                        
     </div>
</div>
                    
                
<div id="accordion" class="col-lg-12">
  <div class="card">
    <div class="card-header" id="heading">
      <h5 class="mb-0">
        <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
          Finalidade
        </button>
      </h5>
    </div>

    <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
      <div class="card-body">
        <ul>
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>
            Destinado a clientes particulares que pretendam adiquerir uma habitação;
        </ul>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header" id="headingTwo">
      <h5 class="mb-0" >
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"> 
          Público – Alvo
        </button>
              
      </h5>
    </div>
    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
      <div class="card-body">
        <ul>
           <i class="fa fa-chevron-circle-right" style="color:red;"></i>	
            Clientes particulares do sector público e privado.
        </ul>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header" id="headingThree">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
          Vantagens
        </button>
      </h5>
    </div>
    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
      <div class="card-body">
        <ul>
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>	Versatilidade - O Crédito ao Consumo é exactamente isso: um crédito que pode usar para qualquer necessidade, seja ela qual for; <br />
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>	Financiamento até 100% do bem ou serviço a adquirir.<br />
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>
            Negociação do montante e prazo adequados a necessidade do cliente; <br />
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>
            Prestações ajustadas ao rendimento líquido do cliente <br />
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>
            Prestações mensais, iguais e consecutivas, de capital e juros <br />
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>
            Taxa de juro fixa<br />
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>
            O valor máximo é conforme a aprovação; <br />

                
        </ul>
      </div>
    </div>
  </div>
<div class="card">
    <div class="card-header" id="headingFour">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
          Despesas e comissões
        </button>
      </h5>
    </div>
    <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
      <div class="card-body">
        <ul>
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>	
            As despesas e comissões decorrentes do processo de financiamento serão debitadas automaticamente na conta do mutuário, de acordo com o plano financeiro acordado.<br />

            Em caso de liquidação antecipada, total ou parcial, do capital em dívida fica sujeito a penalização.        
        </ul>
      </div>
    </div>
  </div>
<div class="card">
    <div class="card-header" id="headingFive">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
          Características
        </button>
      </h5>
    </div>
    <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion">
         
        <div class="card card-body">
                <ul>
                    <p><i class="fa fa-chevron-circle-right" style="color:red;"></i>Idade do Cliente: 18 a 65 anos;<br>
		                              <i class="fa fa-chevron-circle-right" style="color:red;"></i> Volume: Conforme a aprovação;<br>
                                    <i class="fa fa-chevron-circle-right" style="color:red;"></i> Taxa: Taxa de juro mensal fixa;<br/>
                                    <i class="fa fa-chevron-circle-right" style="color:red;"></i> Rendas: Fixas e ajustadas ao seu rendimento líquido 
                                       durante todo o período de vigência do contrato;<br>
		                           <i class="fa fa-chevron-circle-right" style="color:red;"></i> Garantias: * Livrança em branco (obrigatório) <br>
                                    <i class="fa fa-chevron-circle-right" style="color:red;"></i>Hipoteca de imóvel/Penhor DP<br>
		                           <i class="fa fa-chevron-circle-right" style="color:red;"></i>Possibilidade de liquidações antecipadas;<br/>
                                   <i class="fa fa-chevron-circle-right" style="color:red;"></i>Seguro de protecção ao Crédito que cobrira os seus 
                               encargos em caso de necessidades, protegendo-o
                            a si e aos seus.</p>
                </ul>
        </div>
     
        
    </div>
  </div>
<div class="card">
    <div class="card-header" id="headingSix">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
          Documentação
        </button>
      </h5>
    </div>
    <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordion">
         
        <div class="card card-body">
                <ul>
                    <p><i class="fa fa-chevron-circle-right" style="color:red;"></i>Idade do Cliente: 18 a 65 anos;<br>
		                              <i class="fa fa-chevron-circle-right" style="color:red;"></i> Formulário de solicitação de crédito devidamente preenchido;<br>
		                              <i class="fa fa-chevron-circle-right" style="color:red;"></i> Apresentação da cópia do documento de identificação;<br>
                                    <i class="fa fa-chevron-circle-right" style="color:red;"></i> Foto tipo passe;<br/>
                                    <i class="fa fa-chevron-circle-right" style="color:red;"></i> Cópia do NUIT;<br>
		                           <i class="fa fa-chevron-circle-right" style="color:red;"></i> Declaração do Bairro; <br>
                                    <i class="fa fa-chevron-circle-right" style="color:red;"></i>Declaração do Bairro;<br>
		                           <i class="fa fa-chevron-circle-right" style="color:red;"></i>Extracto bancário dos últimos 3 meses;<br/>
                                   </p>
                </ul>
        </div>    
    </div>
  </div>    
    
</div>

        </div>
    </div>
</section>
    
    

    
  </div>
</template>

<script>
import MBreadCrumb from "@/components/Layout/BreadCrumb";

export default {
  name: "Particulares",
  components: { MBreadCrumb }
}
</script>

<style scoped>

</style>